<template>
    <div class="bind-phone" v-if="isLoad">
        <div class="form-container">
            <label class="label">{{bindText}}</label>
            <span class="phone" v-if="phone">{{phone}}</span>
        </div>
        <button class="btn" @click="toPhoneModify">{{btnText}}</button>
    </div>
</template>

<script>
import { authChecker } from '@/common/js/mmcModule';
export default {
    name: 'bindPhone',
    data () {
        return {
            phone: '',
            isLoad: false
        }
    },

    computed: {
        bindText() {
            return this.phone ? '已绑定手机号' : '尚未绑定手机号';
        },
        btnText() {
            return this.phone ? '修改手机号' : '绑定手机号';
        }
    },

    created() {
        showLoading('加载中...');
        authChecker.check({ notneedphone: 1 }, res => {
            hideLoading();

            this.phone = res.data.attr.phone;
            this.isLoad = true;
        });
    },

    methods: {
        toPhoneModify() {
            this.$router.push({
                path: '/fund/my/phonemodify',
            })
        }
    }
}
</script>

<style lang='less' scoped>
.bind-phone {
    padding: 30px 20px;
}
.form-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
}
.label {
    font-size: 16px;
    color: #333;
    line-height: 1;
    font-weight: 600;
}
.phone {
    font-size: 16px;
    color: #333333;
    line-height: 1;
}
.btn {
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    text-align: center;
    line-height: 1;
    padding: 15px 0;
    width: 100%;
    border: none;
    box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
    background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
}
</style>
